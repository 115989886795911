import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom'; //Switch = Routes, Redirect = Navigate
import Preloader from './components/Preloader/Preloader';

// import Home from './pages/Home/Home';
// import GuestList from './pages/Guest/GuestList/GuestList';
// import NewGuest from './pages/Guest/NewGuest/NewGuest';
// import EditGuest from './pages/Guest/EditGuest/EditGuest';
// import QRCode from './pages/QRCode/QRCode';
// import Guest from './pages/Guest/Guest';
// import Gallery from './pages/Gallery/Gallery';

const Home = React.lazy(() => {
  return import('./pages/Home/Home');
});
const Guest = React.lazy(() => {
  return import('./pages/Guest/Guest');
});
const GuestList = React.lazy(() => {
  return import('./pages/Guest/GuestList/GuestList');
});
const NewGuest = React.lazy(() => {
  return import('./pages/Guest/NewGuest/NewGuest');
});
const UpdateGuest = React.lazy(() => {
  return import('./pages/Guest/UpdateGuest/UpdateGuest');
});
const QRCode = React.lazy(() => {
  return import('./pages/QRCode/QRCode');
});
const Gallery = React.lazy(() => {
  return import('./pages/Gallery/Gallery');
});

const App = () => {
  return (
    <Suspense fallback={<Preloader />}>
      <Router>
        <Switch>
            <Route path="/" exact><Home /></Route>
            <Route path="/guestlist" exact><GuestList /></Route>
            <Route path="/newguest" exact><NewGuest /></Route>
            <Route path="/updateguest/:code" exact><UpdateGuest /></Route>
            <Route path="/gallery" exact><Gallery /></Route>
            <Route path="/qrcode" exact><QRCode /></Route>
            <Route path="/:code" exact><Guest /></Route>
            <Redirect to="/" />
        </Switch>
      </Router>
    </Suspense>
)}
export default App;


// https://github.com/machadop1407/Simple-CRUD-React-Node-MySQL/blob/main/client/src/App.js