import React from 'react';
import styles from './Preloader.module.css';
import useWindowDimensions from '../../hooks/useWindowDimensions';

const Preloader = (props) => {
    const { height } = useWindowDimensions();
    return (
        <div className={`${styles.preloaderContainer}`} style={{height: (height)}}>
            {/* <div className={styles.preloader}></div> */}
            <img className={styles.bob} src="img/Logo.png" alt="Girlienell & James"/>
            <div className={styles.center}>
                <div className={styles.wave}>L</div>
                <div className={styles.wave}>O</div>
                <div className={styles.wave}>A</div>
                <div className={styles.wave}>D</div>
                <div className={styles.wave}>I</div>
                <div className={styles.wave}>N</div>
                <div className={styles.wave}>G</div>
            </div>
        </div>
        
    );
};

export default Preloader;